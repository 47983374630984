import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import useAPI from '../../hooks/useAPI';
import log from '../../utils/Logger';
import { GlobalSwitcherContext } from '../GlobalSwitcherContext';

export const StatisticsContext = createContext();

// TODO: Should we keep using localStorage?
export const StatisticsProvider = ({ children }) => {
  const [total, setTotal] = useState({});
  const [currentTotalItem, setCurrentTotalItem] = useState({});
  const [countries, setCountries] = useState([]);
  const [allCountriesName, setAllCountriesName] = useState([]);
  const [allCountriesNameValues, setAllCountriesNameValues] = useState([]);

  const { globalSwitcher } = useContext(GlobalSwitcherContext);
  const { getStatistics } = useAPI();

  const handleStatistics = () => {
    return new Promise((resolve, reject) => {
      getStatistics(globalSwitcher)
        .then((res) => {
          log.debug('Statistics get', res);
          setTotal(res.total);
          const lastTotal = Object.values(res.total);
          setCurrentTotalItem(lastTotal[lastTotal.length - 1]);
          const countriesRes = res.countries.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
          setCountries(countriesRes);
          const tempAllCountriesName = [];
          const tempAllCountriesNameValues = [];
          res.countries.forEach((element) => {
            tempAllCountriesName[element.code] = element.name;
            const { name } = element;
            tempAllCountriesNameValues.push(name);
          });
          setAllCountriesName(tempAllCountriesName);
          setAllCountriesNameValues(tempAllCountriesNameValues);
          resolve();
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  return (
    <StatisticsContext.Provider
      value={{
        total,
        countries,
        currentTotalItem,
        allCountriesName,
        allCountriesNameValues,
        getStatistics: handleStatistics,
      }}
    >
      {children}
    </StatisticsContext.Provider>
  );
};

StatisticsProvider.propTypes = {
  children: PropTypes.node,
};

StatisticsProvider.defaultProps = {
  children: null,
};
