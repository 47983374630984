import React, { Suspense } from 'react';
import { Switch } from 'react-router-dom';
import * as routes from '../../routes';
import RouteDefinition from '../../utils/Route/RouteDefinition';
import Route from '../Route';
import BaseRoute from '../Route/components/BaseRoute';
import Spinner from '../Spinner';

const parsedRoutes = Object.keys(routes).reduce((res, key) => {
  if (!routes[key].register) return res;

  const layoutId = routes[key].layout?.id ?? null;
  const component = routes[key].layout?.component ?? null;
  let layoutIndex = res.findIndex((l) => l.id === layoutId);
  layoutIndex = layoutIndex !== -1 ? layoutIndex : res.length;
  const layoutSettings = res[layoutIndex] ?? {};

  const route = routes[key];
  res[layoutIndex] = {
    id: layoutId,
    routes: [...(layoutSettings?.routes ?? []), route],
    path: [
      ...(layoutSettings?.path ?? []),
      ...(Array.isArray(route.path) ? route.path : [route.path]),
    ],
    component,
  };

  return res;
}, []);

/**
 * Application Router
 *
 * The basic idea behind the router, is to achieve some optimization as well as flexible structure
 * dealing with plain routes. This implementation is at very early stage (there are a lot of things
 * left like subroute support and more).
 * - Routes can be arranged in layout. It means that there's a layoutWrapper than wraps all the
 * routes that are basically threated as the content of the page. We can have multiple different
 * wrapper like the one of the entire application, and one completely different for non logged-in
 * pages.
 * - Routes can be wrapped inside a wrapper. Most likely the layout, but for things like Provider.
 *
 * To make it works we do a very basic trick with react-router-dom. We build one route (plain) for
 * every layout inside the switch with all the path specified from the routes contained. This is
 * needed as the Switch wants every child to be a route. So we cannot use a LayoutWrapper directly
 * inside the switch. What happens at runtime is that since the very first route has a lot of path,
 * as soon as one matches, the LayoutWrapper is rendered (the page, for example). Inside the page
 * we have another switch that will contain the real Route (custom). The inner switch will take care
 * of rendering the right component for the particular path.
 * The trick is reused when wrapping multiple routes inside a wrapper like a provider.
 *
 * A note on the 404 handling
 * Since there could be routes that require path to be exact and other that doesn't, we can mark all
 * the BaseRoute as non exact so that we leave the Route component the exactness.
 * However, if a BaseRoute matches and the route doesn't, we need to render the 404 page. That's
 * why we add a NotFoundContent to every Switch.
 *
 * // TODO: This could be done better using a generator function.
 */
export default function Router() {
  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <Route {...routes.indexRoute} />
        {/* TODO: This could be registered through router but there's a catch using exact path */}
        {parsedRoutes.map((layout) => {
          const LayoutWrapper = layout.component ?? React.Fragment;
          return (
            <BaseRoute key={`layout-${layout.id}`} path={layout.path}>
              <LayoutWrapper>
                <Switch>
                  {layout.routes.map((route) => {
                    if (route instanceof RouteDefinition) {
                      return <Route key={`route-${route.path}`} {...route} />;
                    }
                    const RouteWrapper = React.Fragment;
                    return (
                      <BaseRoute key={`routeWrapper-${route.path}`} path={route.path}>
                        <RouteWrapper>
                          <Switch>
                            {route.routes.map((innerRoute) => (
                              <Route key={`route-${innerRoute.path}`} {...innerRoute} />
                            ))}
                            <Route component={null} isPrivate={false} />
                          </Switch>
                        </RouteWrapper>
                      </BaseRoute>
                    );
                  })}
                  <Route component={null} isPrivate={false} />
                </Switch>
              </LayoutWrapper>
            </BaseRoute>
          );
        })}
        <Route component={null} isPrivate={false} />
      </Switch>
    </Suspense>
  );
}
