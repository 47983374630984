import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useAPI from '../../hooks/useAPI';
import { isEmpty } from '../../utils/String';
import useAppStatus from '../../hooks/useAppStatus';
import { ROLE_ADMINISTRATOR } from '../../constants/roles';
import log from '../../utils/Logger';

export const AuthContext = createContext();

// TODO: Should we keep using localStorage?
export const AuthProvider = ({ children }) => {
  const [isAuth, setAuth] = useState(!isEmpty(localStorage.getItem('token')));
  const [user, setUser] = useState({});
  const { setLoaded } = useAppStatus();

  const { login, getUser, logout } = useAPI();

  const setAuthToken = (token) => {
    if (token) {
      localStorage.setItem('token', token);
      setAuth(true);
    } else {
      localStorage.removeItem('token');
    }
  };

  const handleLogin = (email, password) =>
    login(email, password).then(({ token, data }) => {
      setAuthToken(token);
      return data;
    });

  const handleLogout = () => {
    logout().then(() => {
      setAuthToken();
      window.location.reload();
    });
  };

  useEffect(() => {
    if (!isAuth) {
      // No token stored in local storage.
      setLoaded(true);
      return;
    }

    getUser()
      .then((userData) => {
        setUser({
          ...userData,
          admin: userData.role === ROLE_ADMINISTRATOR,
        });
      })
      .catch((e) => {
        log.error('Failed to retireve user data', e);

        // If an error happen, set the auth flag to false since we cannot be sure whether the user
        // is authenticated or not.
        setAuth(false);

        if (e.statusCode === 401) {
          // If the user is not auth, clear the local storage so that we cannot reuse an expired/invalid token
          localStorage.removeItem('token');
        }
      })
      .finally(() => setLoaded(true));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);

  return (
    <AuthContext.Provider
      value={{
        isAuth,
        user,
        login: handleLogin,
        logout: handleLogout,
        setAuthToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
