import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Root from './components/Root';
import initLoader from './loaders';
import ErrorBoundary from './components/ErrorBoundary';
import Spinner from './components/Spinner';
import './i18n';

initLoader();

ReactDOM.render(
  <ErrorBoundary>
    <Suspense fallback={<Spinner />}>
      <Root />
    </Suspense>
  </ErrorBoundary>,
  document.getElementById('root')
);
