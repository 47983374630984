import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PageContent from '../../components/Page/components/PageContent';

/**
 * A component that renders the access denied page
 */
export default function Error({ message }) {
  const { t } = useTranslation();

  return (
    <PageContent title="Errore">
      <Row className="rowOfPage">
        <h3>{message || t('errorGeneric')}</h3>
      </Row>
    </PageContent>
  );
}

Error.propTypes = {
  message: PropTypes.node,
};

Error.defaultProps = {
  message: undefined,
};
