import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import log from '../../utils/Logger';

export const AppStatusContext = createContext();

export const AppStatusProvider = ({ children }) => {
  const [isLoaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setError((currentErr) => {
      if (!currentErr?.code) return currentErr;

      log.debug('Resetting AppStatus error from', currentErr?.code, 'due to location change');
      return false;
    });
  }, [location]);

  return (
    <AppStatusContext.Provider
      value={{
        isLoaded,
        setLoaded,
        setError,
        error,
      }}
    >
      {children}
    </AppStatusContext.Provider>
  );
};

AppStatusProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
