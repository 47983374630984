import * as ROLES from '../../constants/roles';

/**
 * Get a human readable string of a role
 *
 * @param {string} role The role
 * @returns {string} The hrs
 */
// eslint-disable-next-line import/prefer-default-export
export const getRoleLabel = (role) => {
  switch (role) {
    case ROLES.ROLE_ADMINISTRATOR:
      return 'Administrator';
    case ROLES.ROLE_MANAGER:
      return 'Manager';
    case ROLES.COUNTRY_LEAD:
      return 'Country Lead';
    default:
      return '-';
  }
};
