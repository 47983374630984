import React from 'react';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PageContent from '../../../components/Page/components/PageContent';

export default function NotFoundContent() {
  const { t } = useTranslation();

  return (
    <PageContent title="Page not found">
      <Row className="rowOfPage">
        <h3>{t('notFoundPageText')}</h3>
      </Row>
    </PageContent>
  );
}
