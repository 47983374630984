import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import './style/title.css';

export default function PageTitle({ title, description }) {
  useEffect(() => {}, [title]);

  return (
    <>
      {(title || description) && (
        <Row className="rowOfPage">
          <Col md={9} lg={10} style={{ padding: 0 }} className="page-title">
            {title && <h1 className="titleText">{title}</h1>}
            {description && <p>{description}</p>}
          </Col>
        </Row>
      )}
    </>
  );
}

PageTitle.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

PageTitle.defaultProps = {
  title: null,
  description: null,
};
