import { useCallback, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { hasElement } from '../utils/Array';
import { getRoleLabel } from '../utils/User';

export default function useUser() {
  const { user, isAuth } = useContext(AuthContext);

  /**
   * Check if the current user has a role (or roles)
   *
   * @param {string|string[]} role
   * @returns {Boolean}
   */
  const hasRole = useCallback((role) => hasElement(role, user.roles), [user.roles]);

  /**
   * Check if the current user has a permission (or permissions)
   *
   * @param {string|string[]} permission
   * @returns {Boolean}
   */
  const hasPermission = useCallback((permission) => hasElement(permission, user.permissions), [
    user.permissions,
  ]);

  /**
   * Get user full name
   *
   * @param {boolean} withTitle If true, the hcp title is returned
   * @returns {string}
   */
  const getFullName = useCallback(
    (withTitle = false) => {
      if (!user.admin) {
        return `${withTitle ? user?.title : ''} ${user.name} ${user.surname}`.trim();
      }
      return user.email;
    },
    [user]
  );

  /**
   * Get role name
   *
   * @returns {string}
   */
  const getRoleName = useCallback(() => {
    switch (user.role) {
      case 'administrator':
        return 'Administrator';
      case 'manager':
        return 'Manager';
      default:
        return user.role;
    }
  }, [user]);

  return {
    ...user,
    isAuth,
    isAdmin: !!user.admin,
    hasRole,
    hasPermission,
    getRoleLabel: () => getRoleLabel(user.roles?.[0]),
    getFullName,
    getRoleName,
  };
}
