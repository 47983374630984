import React from 'react';
import { Spinner as BootstrapSpinner } from 'react-bootstrap';
import PropTypes from 'prop-types';

import './style/spinner.css';

/**
 * A component that renders a full page spinner
 */
export default function Spinner({ message, containerClass, messageClass }) {
  return (
    <div className={`spinner-container ${containerClass ?? ''}`}>
      <div>
        <BootstrapSpinner animation="border" role="status" />
      </div>
      {message && (
        <div className={`message ${messageClass ?? ''}`}>
          {React.isValidElement(message) ? message : <p>{message ?? ''}</p>}
        </div>
      )}
    </div>
  );
}

Spinner.propTypes = {
  message: PropTypes.string,
  containerClass: PropTypes.string,
  messageClass: PropTypes.string,
};

Spinner.defaultProps = {
  message: undefined,
  containerClass: undefined,
  messageClass: undefined,
};
