import React, { createContext, useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from '../../utils/String';
import { AuthContext } from '../AuthContext';
import log from '../../utils/Logger';

export const GlobalSwitcherContext = createContext();

// TODO: Should we keep using localStorage?
export const GlobalSwitcherProvider = ({ children }) => {
  const { user } = useContext(AuthContext);
  const [globalSwitcher, setGlobalSwitcher] = useState(false);

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      if (isEmpty(localStorage.getItem('globalSwitcher'))) {
        if (user.admin || (user.commercial && user.study)) {
          setGlobalSwitcher(false);
        } else {
          setGlobalSwitcher(user.study);
        }
      } else {
        setGlobalSwitcher(localStorage.getItem('globalSwitcher') === 'true');
      }
    }
  }, [user]);

  const setGlobalSwitcherValue = (value) => {
    log.debug('setGlobalSwitcherValue whit', value);
    if (value !== undefined) {
      localStorage.setItem('globalSwitcher', value);
      setGlobalSwitcher(value);
      window.location.reload();
    } else {
      localStorage.removeItem('globalSwitcher');
    }
  };

  const showGlobalSwitcher = () => user.admin || (user.commercial && user.study);

  return (
    <GlobalSwitcherContext.Provider
      value={{
        globalSwitcher,
        setGlobalSwitcherValue,
        showGlobalSwitcher,
      }}
    >
      {children}
    </GlobalSwitcherContext.Provider>
  );
};

GlobalSwitcherProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
