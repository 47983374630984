import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from '../../context/AuthContext';
import { AppStatusProvider } from '../../context/AppStatusContext';
import { SpinnerProvider } from '../../context/SpinnerContext';
import StatusMessageProvider from '../Page/context/StatusMessageContext';
import { ModalProvider } from '../../context/ModalContext';
import { StatisticsProvider } from '../../context/StatisticsContext';
import { GlobalSwitcherProvider } from '../../context/GlobalSwitcherContext';
import Router from '../Router';

import './style/root.css';

export default function Root() {
  return (
    <BrowserRouter>
      <SpinnerProvider>
        <AppStatusProvider>
          <AuthProvider>
            <StatusMessageProvider>
              <ModalProvider>
                <GlobalSwitcherProvider>
                  <StatisticsProvider>
                    <Router />
                  </StatisticsProvider>
                </GlobalSwitcherProvider>
              </ModalProvider>
            </StatusMessageProvider>
          </AuthProvider>
        </AppStatusProvider>
      </SpinnerProvider>
    </BrowserRouter>
  );
}
