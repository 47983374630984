import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isFunction } from '../../../../utils/Promise';
import PageTitle from './components/PageTitle';
import StatusMessageConsumer from './components/StatusMessageConsumer';
import ComponentLoader from '../../../ComponentLoader';
import { StatisticsContext } from '../../../../context/StatisticsContext';
import useAppStatus from '../../../../hooks/useAppStatus';
import { ERROR_GENERIC } from '../../../../constants/appStatusErrors';

export default function PageContent({
  title,
  description,
  loading,
  loadingMessage,
  children,
  withStatistics,
}) {
  const { countries, getStatistics } = useContext(StatisticsContext);
  const [pageLoading, setPageloading] = useState(true);
  const { setError } = useAppStatus();

  useEffect(() => {
    if (withStatistics) {
      if (countries.length) {
        setPageloading(false);
      } else {
        getStatistics()
          .then(() => {
            setPageloading(false);
          })
          .catch(() => {
            setError(ERROR_GENERIC, 'Error retrieving statistics from iGRO');
          });
      }
    } else {
      setPageloading(false);
    }
  }, [getStatistics, withStatistics, countries.length, setError]);

  if (isFunction(children)) {
    return children({ PageTitle, StatusMessageConsumer });
  }

  return (
    <ComponentLoader loading={pageLoading || loading} message={loadingMessage}>
      <>
        <PageTitle title={title} description={description} />
        <StatusMessageConsumer />
        {children}
      </>
    </ComponentLoader>
  );
}

PageContent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node,
  loading: PropTypes.bool,
  loadingMessage: PropTypes.string,
  withStatistics: PropTypes.bool,
};

PageContent.defaultProps = {
  title: undefined,
  description: undefined,
  children: null,
  loading: false,
  loadingMessage: undefined,
  withStatistics: false,
};
