import React from 'react';
import axios from 'axios';
import log from '../utils/Logger';
import APIError from '../utils/APIError';
import { isEmpty } from '../utils/String';

// TODO: Check isEmpty usage
log.debug('Building axios instance');
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_GATEWAY_URL ?? '',
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (!isEmpty(token))
      // eslint-disable-next-line no-param-reassign
      config.headers.authorization = `Bearer ${token}`;

    log.debug(
      `${config.method.toUpperCase()}: ${config.baseURL}${config.url} ${
        config.params ? JSON.stringify(config.params) : ''
      } ${config.data ? JSON.stringify(config.data) : ''}`
    );
    return config;
  },
  (e) => {
    log.error(e);
    return Promise.reject(e);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (res) => {
    // Log response data
    log.debug('Response:', res.data);
    return res.config.fullResponse ? res : res.data;
  },
  (e) => {
    // TODO: Implement refresh token.
    // TODO: Handle 401
    // NOTE: When dealing with 401, the login/logout methods needs to be moved inside UserContext
    // so that this hook can use the UserContext to logout the user in case of failure.
    // TODO: Is this ideal to avoid the useAPI hook doing more than just API calls?
    const statusCode = e.response?.status;
    const errorData = e.response?.data;
    const path = `${e.config?.baseURL}${e.config?.url}`;
    const method = e.config?.method?.toUpperCase();
    if (e.config.logError == null || e.config.logError)
      log.error(`APIError at ${method} ${path}`, null, {
        statusCode,
        errorData,
      });

    return Promise.reject(new APIError(e.message, errorData, statusCode));
  }
);

const useAPI = () => {
  /**
   * User login.
   *
   * @param {*} email
   * @param {*} password
   */
  const login = (email, password) =>
    axiosInstance
      .post(
        '/user/login',
        {
          email,
          password,
        },
        { fullResponse: true }
      )
      .then(({ data, headers }) => {
        log.debug('Headers login ---->', headers);
        const token = headers.authorization?.split(' ')?.[1];
        if (isEmpty(token)) throw new APIError('Invalid authorization header', data, 500);
        return {
          token,
          data,
        };
      });

  /**
   * Reset password for the email passed in input.
   *
   * @param {*} email
   */
  const forgotPasswordEmail = (email) => axiosInstance.post(`/user/forgot-password`, { email });

  /**
   * Verify resetPassword token
   *
   * @param {*} token
   */
  const resetPasswordTokenVerify = (token) =>
    axiosInstance.get('/user/reset-password', { params: { token } });

  /**
   * reset user password
   *
   * @param {*} token
   */
  const resetPassword = (token, password) =>
    axiosInstance.post('/user/reset-password', { token, password }, { fullResponse: true });

  /**
   * Logout API.
   */
  const logout = () => axiosInstance.delete('/user/logout');

  /**
   * Get user information data.
   *
   */
  const getUser = () => axiosInstance.get('/user');

  /**
   * Create invite
   *
   * @param {String} email
   * @param {String} title
   * @param {String} name
   * @param {String} surname
   * @param {boolean} commercial
   * @param {boolean} study
   */
  const createInvite = (data) => axiosInstance.post('/invite', data);

  /**
   * @param {Object} paginationData data for pagition result
   */
  const listInvite = (paginationData) =>
    axiosInstance.get(`/invite/list`, { params: { ...paginationData } });

  /**
   * Resend invite
   *
   * @param {*} inviteId
   */
  const resendInvite = (inviteId) => axiosInstance.patch(`/invite/${inviteId}/resend`);

  /**
   * Get invite data
   *
   * @param {*} inviteCode
   */
  const getInviteData = (inviteCode) => axiosInstance.get(`/invite/code/${inviteCode}`);

  /**
   * Accept invite
   *
   * @param {*} code
   * @param {*} data
   */
  const acceptInvite = (code, data) =>
    axiosInstance.patch(`/invite/${code}/accept`, data, {
      fullResponse: true,
    });

  /**
   * getStatistics
   *
   * @param {boolean} studyFalg
   */
  const getStatistics = (studyFalg) => axiosInstance.get(`/statistics?study=${studyFalg}`);

  const downloadReportStatistics = (params) =>
    axiosInstance.get('/statistics/export', {
      responseType: 'blob',
      params,
      fullResponse: true,
    });

  const downloadExcelFromTableData = (body, fileName) =>
    axiosInstance.post(`/statistics/export?fileName=${fileName}`, body, {
      responseType: 'blob',
      fullResponse: true,
    });

  return React.useMemo(
    () => ({
      login,
      forgotPasswordEmail,
      resetPasswordTokenVerify,
      resetPassword,
      getUser,
      createInvite,
      listInvite,
      resendInvite,
      getInviteData,
      acceptInvite,
      logout,
      getStatistics,
      downloadReportStatistics,
      downloadExcelFromTableData,
    }),
    []
  );
};

export default useAPI;
