/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import log from '../../utils/Logger';
import { isDevelopment } from '../../utils/Environment';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    log.error(`ErrorBoundary: ${error?.message}`, {
      stack: isDevelopment() ? errorInfo?.componentStack : error?.stack,
    });
  }

  render() {
    if (this.state.hasError) {
      return <h3>Errore durante l&apos;elaborazione della richiesta. Riprovare più tardi.</h3>;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};
