import React from 'react';
import { ROLE_ADMINISTRATOR } from '../constants/roles';
import RouteDefinition from '../utils/Route/RouteDefinition';

/**
 * Export all routes that will be registered through the app.
 */
export const indexRoute = new RouteDefinition({
  path: '/',
  component: React.lazy(() => import('../pages/Index')),
  isPrivate: false,
  register: false,
});

/* USER */
export const loginRoute = new RouteDefinition({
  path: '/user/login',
  isPrivate: false,
  component: React.lazy(() => import('../pages/Login')),
  layout: null,
});

export const forgotPasswordRoute = new RouteDefinition({
  path: '/user/forgot-password',
  isPrivate: false,
  component: React.lazy(() => import('../pages/ForgotPassword')),
  layout: null,
});

export const resetPasswordRoute = new RouteDefinition({
  path: '/user/reset-password',
  isPrivate: false,
  component: React.lazy(() => import('../pages/ResetPassword')),
  layout: null,
});

/* DASHBOARD */
export const dashboardRoute = new RouteDefinition({
  path: '/dashboard',
  component: React.lazy(() => import('../pages/Dashboard')),
});

export const patientsRoute = new RouteDefinition({
  path: '/patients',
  component: React.lazy(() => import('../pages/Patients')),
});

export const visitsRoute = new RouteDefinition({
  path: '/visits',
  component: React.lazy(() => import('../pages/Visits')),
});

export const physiciansRoute = new RouteDefinition({
  path: '/physicians',
  component: React.lazy(() => import('../pages/Physicians')),
});

export const centersRoute = new RouteDefinition({
  path: '/centers',
  component: React.lazy(() => import('../pages/Centers')),
});

export const exportRoute = new RouteDefinition({
  path: '/export',
  component: React.lazy(() => import('../pages/Exports')),
});

/* INVITE */
export const acceptInviteRoute = new RouteDefinition({
  path: '/invite/accept/:inviteCode',
  isPrivate: false,
  component: React.lazy(() => import('../pages/Invite/Accept')),
});

export const inviteRoute = new RouteDefinition({
  path: '/invite',
  component: React.lazy(() => import('../pages/Invite/Manage')),
  access: (user) => user.hasRole(ROLE_ADMINISTRATOR),
  exact: false,
});
